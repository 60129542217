import React from 'react';

const CenterArea = () => {
 
  return (
    <div className='centerarea'  >
      <p style={{ fontSize: '50pt' }}>Webslate</p>
    </div>
  );
};

export default CenterArea;