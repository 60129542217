import React from 'react';
import '../../styles.css'

const AboutUs = () => {
    return (
        <div className="aboutus-container">
            <div className="aboutus-section">
                <div className="aboutus-header">
                    <h2>About Us</h2>
                </div>
            
            
                <div className="aboutus-content">
                    <p>- Webslate is a fully US based company that employs cutting edge tech to compete with major industry competitors for a much smaller cost to our clients. All work is personally done by highly educated industry professionals and specialists with your input and ideas to realize your business' goals and dreams online</p>
                    <p>- should probably fill out a paragraph about us/owners and some small synopsis about how webslate came about yadda yadda</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;